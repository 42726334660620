// third-party
import { FormattedMessage } from "react-intl";
import {
  ClassOutlined,
  Inventory,
  BrandingWatermarkOutlined,
  AutoStories,
  StickyNote2,
} from "@mui/icons-material";

// type
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - PAGES ||============================== //

const values: NavItemType = {
  id: "other",
  type: "group",
  roles: ['admin', 'seo'],
  children: [
    {
      id: "registers",
      title: <FormattedMessage id="registers" />,
      type: "collapse",
      icon: Inventory,
      roles: ['admin'],
      children: [
        {
          id: "services",
          title: <FormattedMessage id="services" />,
          type: "item",
          url: "/services",
          icon: ClassOutlined,
        },
        {
          id: "brands",
          title: <FormattedMessage id="brands" />,
          type: "item",
          url: "/brands",
          icon: BrandingWatermarkOutlined,
        },
      ],
    },
    {
      id: "custom-pages",
      title: "Контент",
      type: "collapse",
      icon: AutoStories,
      roles: ['admin', 'seo'],
      children: [
        {
          id: "filtered-pages",
          title: "Кастомні сторінки",
          type: "item",
          url: "/filtered-pages",
          icon: AutoStories,
        },
        {
          id: "blog-posts",
          title: "Блог пости",
          type: "item",
          url: "/blog-posts",
          icon: StickyNote2,
        },
      ],
    },
  ],
};

export default values;
